import { List, FlexboxGrid } from 'rsuite';
import { CustomProvider } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import * as React from 'react';
import { Button } from '@mui/material';
import { Loader } from 'rsuite';
const styleRight = {
    bottomStart : 0,
    display: 'flex',
    float: 'right', 
    
    alignItems: 'center', 
    justifyContent : 'right',
   paddingTop:6
};

const slimText = {
  fontSize: '1.1em',
  
  
  
};


const dataStyle = {
  fontSize: '1.7em',
  fontWeight: 500,
  width: '90%',
  fontSize: '20pt',
  marginLeft: '0px',
  position:'absolute',
  top:35,
  left:0
};

export default function Classifica() {
  React.useEffect(() => {
    document.title = 'Classifica';
  }, [])
  localStorage.setItem('page',2)
  const [page, setPage] = React.useState(1);
  const [loadmore, setLoadMore]= React.useState(true)
  const [lastuser, setLast] = React.useState('?lastuser=');
  const [classifica, setCl] = React.useState([]);
  const [counter, setCount] = React.useState(0);
  const [array,setAr]= React.useState([]);
  const add = (itemToBeAdded) => {
    const replacementList = [...array, ...itemToBeAdded];
    setAr(replacementList);
  }
  var classif=[]
  const handleChange = (event, value) => {
    if(classifica[7])setLast('?lastuser='+classifica[7].username)
    else setLoadMore(false)
    // console.log(lastuser)
    if(classif!=null)add(classif)
    else setLoadMore(false)
    // console.log(classif)
    setPage(value);
  };
  React.useEffect(()=>{
    async function getdata(){
     var response= await fetch(`https://z7b1yprr7b.execute-api.eu-south-1.amazonaws.com/classifica${lastuser}`,{
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`, // notice the Bearer before your token
            }
        })
        classif=await response.json()
        if(classif!=null)setCl(classif)
        else setLoadMore(false)
        if(classif!=null)add(classif)
        else setLoadMore(false)
      //  console.log(array)
        
    }
    getdata()
    
  },[lastuser])


  return (
<>
<CustomProvider theme='high-contrast'>
  <div style={{paddingBottom: '100px'}}>
      {classifica==[]?'caricamento':array.map((item, index) => {
        var color,font
        if(localStorage.getItem("username")==item['username']){
          color='#4dabf5'
          font='black'
        }
        
       return <List.Item key={index} index={index + 1} style={{backgroundColor:color,color:font}}>
          <FlexboxGrid >
            <FlexboxGrid.Item
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div style={{padding: '3.5%', display: 'flex',alignItems: 'left',fontSize:20}}>{index+1}</div>
              <div style={slimText}>
                <div >
                  {index==0&&item['fanpunti']!=0?item['username']+'🥇' :
                  index==1&&item['fanpunti']!=0?item['username']+'🥈':
                  index==2&&item['fanpunti']!=0?item['username']+'🥉':
                  ''+ item['username']}
                </div>
                <div style={{fontSize: '0.8em',color: '#97969B',fontWeight: 'lighter',}}>{item['date']}</div>
              </div>
              <FlexboxGrid.Item style={styleRight}>
              <div style={{ width: '100% ', textAlign: 'right' }}>
                <div style={{...styleRight,width: '100% ',alignItems: 'right', paddingLeft:60,   
                    width: '90%',
                    fontSize: '15pt',
                    marginRight: '5px',
                    marginLeft: '10px',
                    position:'absolute',
                    top:0,
                    left:0
            }}>Punti Ottenuti</div>
                <div style={dataStyle}>{item['fanpunti'].toLocaleString()}</div>
              </div>
              
            </FlexboxGrid.Item>
            </FlexboxGrid.Item>
        
          
          </FlexboxGrid>
          
        </List.Item>
        
          })} {loadmore?<Button fullWidth sx={{ mt: 2, mb: 1 }} onClick={handleChange}>Carica altro</Button>:''}
     </div>
      </CustomProvider>
      </>
  );
};