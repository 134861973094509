import * as React from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RadioGroup,Radio,FormControlLabel } from '@mui/material';
import UserPool from "../components/userPool.js"
import Countdown from 'react-countdown';
import { Loader } from 'rsuite';
import { useEffect } from 'react';
import {
  CustomProvider,
  Notification,
  useToaster,
} from 'rsuite';
import {CognitoUserAttribute, AuthenticationDetails,CognitoUser} from "amazon-cognito-identity-js"


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'AGC © '}
      
        Seagulls & Audace
        {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );

}




const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});



export default function Registrazione({login}) {
  useEffect(() => {
    document.title = 'Registrati';
  }, [])
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const toaster = useToaster();


const message4 = (
  <CustomProvider theme='dark'>
  <Notification style={{ width: 320 }} type="error"  header='Errore' closable>
  Utente Esistente <Link href="/accedi" variant="body2">
                  Accedi
                </Link>
  </Notification>
  </CustomProvider>
);
 
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
  const [email, setEmail]=React.useState();
const [password, setPassword]=React.useState();
const [reset, setReset]=React.useState(0);
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
const handleSubmit2 = (event) => {
  const data = new FormData(event.currentTarget);
event.preventDefault();
var cognitoUser = new CognitoUser({
  Username: email,
	Pool: UserPool,
});
cognitoUser.confirmRegistration(data.get('code'), true, function(err, result) {
  if (err) {
    alert(err.message || JSON.stringify(err));
    return;
  }
  if(result=='SUCCESS'){
    // console.log('inzio autenticazione')
    cognitoUser.authenticateUser(new AuthenticationDetails({
      Username:email,
      Password:password
    }), {
      onSuccess: function(result) {
        var accessToken = result.getAccessToken().getJwtToken();
        // console.log(accessToken)
        login()
      },
    
      onFailure: function(err) {
        alert(err.message || JSON.stringify(err));
      },
    });
  }
});
}
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = (event) => {
    setLoading(true)
 const data = new FormData(event.currentTarget);
 event.preventDefault();

 if(value&&data.get('password')&&data.get('email')&&data.get('nome')&&data.get('Cognome')){
  setPassword(data.get('password'))
  setEmail(data.get('email'))
      
      var attributeName = new CognitoUserAttribute({Name:"given_name",Value: data.get('nome')});
      var attributeSurname = new CognitoUserAttribute({Name:"family_name",Value: data.get('Cognome')});
  UserPool.signUp(data.get('email'),data.get('password'),[attributeSurname,attributeName],null,(err,data)=>{
    if(err){
      if(err.message=='User already exists')toaster.push(message4, 'top')
      else alert('La password deve contenere un carattare speciale lettere e numeri e minimo una lettera maiuscola')
      setLoading(false)
    }
    else{
      // console.log(data.user)
      var cognitoUser = data.user;
      // login(cognitoUser)
      setOpen(true)
      
    }
    
    
  })
      
      function validateEmail(emailField){
          var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
          if (reg.test(emailField) == false) 
          {
              // console.log('test ',reg.test(emailField.value))
              alert('Indirizzo Email non valido');
              return false;
          }
  
          return true;
  
  }
  
        // console.log(validateEmail(data.get('email').toString()))
 }else{
  
  // console.log('manca qualcosa')
 
    alert('Compila tutti i campi')
    setLoading(false)
  
 }

  };

  return (
    <ThemeProvider theme={darkTheme}>
      {window.onbeforeunload = open?function() {return "";}:null}
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Crea Account
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              disabled={loading}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
               <TextField
               disabled={loading}
              margin="normal"
              required
              fullWidth
              id="Nome"
              label="Nome"
              name="nome"
              autoComplete="nome"
              autoFocus
            />
            <TextField
            disabled={loading}
              margin="normal"
              required
              fullWidth
              id="Cognome"
              label="Cognome"
              name="Cognome"
              autoComplete="Cognome"
              autoFocus
            />
            <TextField
            disabled={loading}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <RadioGroup
              aria-labelledby="1"
              required
              name="Privacy"
              value={value}
              onChange={(event) => {
                if (event.target.value === value) {
                  setValue("");
                } else {
                  setValue(event.target.value);
                }
                setError(false)}}>
              <FormControlLabel value='privacy' control={<Radio disabled={loading}/>} label={<>Ho letto e accetto i&nbsp; <Link color="inherit" href="">
        termini di servizio
      </Link></>} onClick={(event) => {
                if (event.target.value === value) {
                  setValue("");
                } else {
                  setValue(event.target.value);
                }
                setError(false)}}/>
            </RadioGroup>
           
            <Button
              disabled={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
            { loading?<><Loader/>Caricamento</>:'Prosegui'}
            </Button>
            <Grid container>
              <Grid item>
                <Link href="/accedi" variant="body2">
                  {"Hai gia' un account? Accedi"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <Modal
        sx={{background: 'rgba(239, 239, 240, 0.5)'}}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box component="form" sx={{...style,alignItems:'center',backgroundColor:'black',width:'80%'}}onSubmit={handleSubmit2} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
           Inserisci il codice inviato alla tua email
          </Typography>
          <TextField
              margin="normal"
              required
              fullWidth
              name="code"
              label="codice"
              id="code"
            />
            <Countdown
            key={reset}
              date={Date.now() + 60000}
              renderer={({ hours, minutes, seconds, completed })=>{
                if (completed) {
                  // Render a completed state
                  return <Button sx={{ mt: 1, mb: 2 }} variant="text" onClick={()=>{
                    var cognitoUser = new CognitoUser({
                     Username: email,
                     Pool: UserPool,
                   });
                   cognitoUser.resendConfirmationCode(function(err, result) {
                     if (err) {
                       alert(err.message || JSON.stringify(err));
                       return;
                     }
                    //  console.log('call result: ' + result);
                     setReset(getRandomInt(1000))
                   });
                 }}>Reinvia codice</Button>;
                } else {
                  // Render a countdown
                  return <span>{minutes}:{seconds}</span>;
                }
              }}
            />
          
          <Button
              type="submit"
              fullWidth
              variant="contained"
              
            >
              Crea Account
            </Button>
        </Box>
        
      </Modal>
    </ThemeProvider>
  );
}