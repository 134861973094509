import './App.css'
import Quiz from './components/quizcard'
import {useQuery} from 'react-query'
import background from './components/imgs/sfondo.png'
import CardMedia from '@mui/material/CardMedia';
import { Loader } from 'rsuite';
import { useEffect } from 'react';
function App({userid}) {
  useEffect(() => {
    document.title = 'Home';
  }, [])
  localStorage.setItem('page',0)
  var content=true
    const {data: posts, isLoading}=useQuery({
        queryFn: async ()=>{

         var response= await fetch("https://z7b1yprr7b.execute-api.eu-south-1.amazonaws.com/posts")
         return await response.json()
         
        },
        queryKey:['posts']
      })


    
      function display(){
        posts.sort(function(a,b) {
          a = a.data.split('/').reverse().join('');
          b = b.data.split('/').reverse().join('');
          return a > b ? 1 : a < b ? -1 : 0;
          // return a.localeCompare(b);         // <-- alternative 
        });
        return posts.map((post,index)=>{
          const f= new Intl.DateTimeFormat('en',{
           
           
          })
          var today = new Date();
         var dd = String(today.getDate()).padStart(2, '0');
         var mm = String(today.getMonth() + 1).padStart(2, '0');
         var yyyy = (today.getFullYear());
         today = dd + '/' + mm + '/' + yyyy;
         function stringToDate(str) {
           const [dd, mm, yyyy] = str.split('/');
           return new Date(yyyy, mm - 1, dd);
         }
        //  console.log(new Intl.DateTimeFormat('it',{
        //   dateStyle: 'short',
        //  }).format())
       //    disabled={!(f.format()==post.data)}
          if(stringToDate(today)<=stringToDate(post.data)) return <Quiz key={index}titolo={post.titolo} data={new Intl.DateTimeFormat('it',{
            weekday:'short',
            day:'numeric',
            month:'numeric'
            
           }).format(stringToDate(post.data))} ora={post.ora} squadra2={post.squadra2} squadra1={post.squadra1} qr={userid} img={require(post.img=='audace'?'./components/imgs/audace.png':'./components/imgs/sea-gulls.png') }disabled={!(new Intl.DateTimeFormat('en-GB', {}).format()==post.data)} color={post.img=='audace'?'white':'black'}content={post.comunicazione?post.comunicazione:true}/> 
       })
      }

      
    return (
        <div style={{backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundImage:`url(${background})`,width:'100%',height:'100%'}}><div className='cardmedia'><CardMedia
        sx={{position:'fixed',zIndex:50,backgroundColor:'#0b75cc',minWidth:140,maxWidth:540,alignSelf:'center'}}
        component="img"
        
        image={require('./components/imgs/testata.png')}
      /></div>
            
            
                {isLoading? <Loader size="md" center vertical content="camento" /> :<div className='quiz' style={{paddingBottom: '60px' }}>{display()} </div> }
             
               
            </div>
           
    );
  }
  
  export default App;
  