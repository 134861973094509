
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import CardMedia from '@mui/material/CardMedia';
import gif from '../components/imgs/qrscan.gif'
import gif2 from '../components/imgs/points.gif'
import React, { useState, useEffect } from 'react';
import Sheet from 'react-modal-sheet';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import UserPool from "../components/userPool.js"
import QRCode from 'react-qr-code';
import FaceIcon from '@mui/icons-material/Face';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { color } from 'framer-motion';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  p: 4,
};

export default function App({email,userid}) {
  useEffect(() => {
    document.title = 'Account';
  }, [])
  localStorage.setItem('page',3)
  var cognitoUser = UserPool.getCurrentUser();
function Logout(){
  cognitoUser.signOut();
  localStorage.clear()
  window.location.href = "/";
}

const [scrollPosition, setScrollPosition] = useState(0);
const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    
};
const [open, setOpen] = React.useState(false);
const [open1, setOpen1] = React.useState(false);
useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      
    };
}, [true,scrollPosition,open]);
  
   
  const handleOpen = () =>{
    if(scrollPosition < 90)setOpen(true);
  }
  const handleClose = () => setOpen(false);

  return (
 <>
 <Button size="large" sx={{    
            height:'50px',
            width: '50px',
            fontSize: '10pt',
            marginTop: '15px',
            marginRight: '10px',
            position:'absolute',
            top:0,
            right:0}} variant="text" onClick={() => Logout()}>  <LogoutIcon style={{height:'35px',width: '35px',}}/></Button>
<div style={{    
           height:'50px',
           width: '55%',
           fontSize: '20pt',
           marginTop: '20px',
           marginRight: '10px',
           marginLeft: '10px',
           position:'absolute',
           top:0,
           left:0
            }}>{localStorage.getItem('fanpunti')} ⭐   {localStorage.getItem('tickets')} 🎟️

</div>
<div style={{  
      display: 'flex',
      textAlign: 'center',
      flexDirection:'column',
      transform: 'translate(0%, 200%)',
      overflow:'hidden',zIndex:1
      }}>
        
       
      <Button onClick={handleOpen}><Avatar sx={{zIndex:2,bgcolor: '#098af3',width: 94, height: 94,fontSize:54
           
          }}><FaceIcon style={{fontSize:'80'}}/></Avatar></Button> <div style={{whiteSpace: 'nowrap',
          fontWeight: 600}}>{localStorage.getItem('username')}<IconButton sx={{bottom:0, transform: 'translate(0%, -5%)'}} variant="text" onClick={() => setOpen1(true)}><InfoIcon/></IconButton></div>
         
           
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={{...style,backgroundColor:'white', color:'black',width:'90%', height:'60%'}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
           Codice QR
          </Typography>
          
          <QRCode  size={256}
                  style={{ ...style,height: "auto", maxWidth: "80%", width: "80%" }}
                  
                  value={userid}/>
        
        </Box>
        
      </Modal>
      </div>
      
     
      
       
    

<Sheet isOpen={open1} onClose={() => setOpen1(false)} >
  <Sheet.Container >
    <Sheet.Header />
    <Sheet.Scroller ><div><div style={{fontSize:20,color:'black'}}>Ciao {localStorage.getItem('username')}, utilizza il tuo codice <strong>QR</strong> <QrCodeIcon sx={{transform:"translate(0%,25%)"}}/> personale per ottenere <strong>Fanpunti⭐</strong> guradando le partite dal vivo facendolo scansionare all'ingresso!</div>
           <CardMedia
             component="img"
             width='250'
             height="200"
             image={gif}
           />
           <div style={{fontSize:20,color:'black'}}>Colleziona piu' <strong>Fanpunti⭐</strong> possibili vedendo le partite o rispondendo a i quiz giornalieri</div>
           <CardMedia
             component="img"
             width='250'
             height="200"
             image={gif2}
           /></div>
                      <div style={{fontSize:20,color:'black'}}>Ogni volta che avrai accumulato venti <strong>Fanpunti⭐</strong> otterai un <strong>Ticket 🎟️</strong>, che ti dara' la possibilita' di essere estratto durante le partite o in sessioni extra, per ottenere dei premi speciali </div>
       </Sheet.Scroller>
  </Sheet.Container>
  <Sheet.Backdrop />
</Sheet>
        

        
           
        
       


      </>
  );
  }
  
